import AddToCartFormik from '@jetshop/core/components/Mutation/AddToCartFormik';
import t from '@jetshop/intl';
import theme from '@jetshop/ui/utils/theme';
import { Form } from 'formik';
import React from 'react';
import styled from 'react-emotion';
import CartQuery from '../../Cart/CartQuery.gql';
import addToCartMutation from '../../Cart/addToCart.gql';
import InputWithLabel from '../../Forms/Input';
import Button from '../../ui/Button';
import StockNotification from '../StockNotification';

import { DrawerTrigger } from '@jetshop/ui/Modal/Drawer/DrawerTrigger';

const StyledForm = styled(Form)`
    display: flex;
    width: 100%;
    flex-direction: column;

    ${theme('above.md')} {
        width: 72.5%;
    }

    ${({ theme }) => theme.below.md} {
        align-items: center;
    }
`;

const QuantityAddToCartWrapper = styled('div')`
    display: flex;
    width: 100%;
    align-items: flex-end;

    input {
        border-color: #cecece;
        width: 54px;
        height: 54px;
        font-size: 20px;
        text-align: center;
        padding: 0px;
        background: white;
        margin: 0 12px 0 0;
        appearance: textfield;

        ::-webkit-outer-spin-button,
        ::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
    }
`;

const BuyButton = styled(Button)`
    background: ${theme('colors.buy')};
    text-transform: uppercase;
    font-family: 'Abel', sans-serif;
    font-size: 1.3rem;
`;

function AddToCartForm({
                         product,
                         variant,
                         getMissingOptions,
                         variantHandler,
                         productStockStatus
                       }) {
  const onAddToCartError = () => {
    return ({ error, ...rest }) => {
      console.log(error);
    };
  };

  const onAddToCartInit = () => {
    return ({ error, ...rest }) => {
      console.log(error);
    };
  };

  const { variantValidation } = variantHandler;

  console.log(product, ' - product');
  console.log(variant, ' - variant');
  console.log(variantValidation, ' - variantValidation');


  return (
    <AddToCartFormik
      onAddToCartInit={onAddToCartInit}
      onAddToCartError={onAddToCartError}
      cartQuery={CartQuery}
      cartMutation={addToCartMutation}
      product={product}
      variant={variant}
      getMissingOptions={getMissingOptions}
    >
      {() => {
        return productStockStatus.buyable ? (
          <StyledForm>
            <QuantityAddToCartWrapper>
              <InputWithLabel
                disableValidation
                showLabel={false}
                wrapperClassName='quantity-input-wrapper'
                min='1'
                type='number'
                name='quantity'
                label='Quantity'
              />
              <OnAddToCart>
                {(props) => (
                  <BuyButton
                    data-testid='add-to-cart'
                    type='submit'
                    disabled={(variantValidation === 'invalid') || (product?.hasVariants && !variant)}
                    onClick={() => props.showTarget(true)}
                  >
                    {product?.hasVariants && !variant ? t('Select variant above') : t('Add to cart')}
                  </BuyButton>
                )}
              </OnAddToCart>
            </QuantityAddToCartWrapper>
          </StyledForm>
        ) : (
          <StockNotification
            articleNumber={variant?.articleNumber || product.articleNumber}
          />
        );
      }}
    </AddToCartFormik>
  );
}

function OnAddToCart({ children }) {
  return (
    <DrawerTrigger preventOverflow={true} id="cart-drawer">
      {(props) => children(props)}
    </DrawerTrigger>
  );
}

export { OnAddToCart };
export default AddToCartForm;
