import get from 'lodash.get';
import React, { Fragment } from 'react';
import styled from 'react-emotion';

import CartProvider from '@jetshop/core/components/Query/CartProvider';
import t from '@jetshop/intl';
import Drawer, { DrawerTarget } from '@jetshop/ui/Modal/Drawer';
import theme from '@jetshop/ui/utils/theme';
import { Price } from '../Price';

import Button from '../ui/Button';
import CartItem from './CartItem';
import cartQuery from './CartQuery.gql';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faShoppingCart, faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CloseIcon, MenuTitle } from '../Layout/Header/Header';
import { VatContext } from '../Price/VatContext';
library.add(faShoppingCart, faTimes);

const Flyout = styled('div')`
  background: white;
  color: ${theme('colors.black')};

  .scroll-wrapper {
    overflow: auto;
    max-height: 73vh;
  }

  ${({ theme }) => theme.above.md} {
    position: absolute;
    z-index: 999;
    right: ${({ isOpen }) => (isOpen ? 0 : '-20rem')};
    top: 0;
    width: 40rem;
    height: 100rem !important;
    padding: 1rem;
    transition: right 0.3s ease;
    .scroll-wrapper {
      max-height: 90vh;
      > section {
        max-height: 900px;
      }
    }
  }

  padding: 20px;
`;

const Header = styled('header')`
  position: relative;
  text-align: left;
  border-bottom: 1px solid #e8e8e8;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 0 0 1rem 0;
  margin: 0 0 1rem 0;
  h2 {
    font-size: 1.5rem;
    text-transform: uppercase;
    ${theme('below.md')} {
      font-size: 1.5rem;
    }
  }
`;

const CartHeaderIcon = styled(FontAwesomeIcon)`
  font-size: 2rem;
  margin-right: 10px;
`;

const CartItems = styled('section')`
  max-height: 280px;
  min-height: 160px;
  overflow-y: auto;
  height: auto;
  padding: 0;
`;

const Summary = styled('section')`
  background: #fff;
  margin: 0;

  a {
    width: 100%;
    color: white;
    :hover {
      color: white;
    }
  }
`;

const SummaryTitle = styled('div')`
  font-size: 1.4rem;
  text-transform: uppercase;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #ddd;
`;

const SummaryRow = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const DeliverySummary = styled('div')`
  text-align: center;
  margin-bottom: 1rem;
`;
const DeliverySummaryText = styled('span')`
  font-size: 1.2rem;
  color: ${theme('colors.green')};
  text-transform: uppercase;
`;

const TotalSummary = styled('div')`
  font-size: 1.2rem;
  color: ${theme('colors.black')};
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid #ddd;
`;

const CheckoutButtonWrapper = styled('div')`
  ${theme('below.md')} {
    padding-bottom: 60px;
  }
  a {
    width: 100%;
    color: white;
    font-family: 'Abel', sans-serif;
    font-size: 1.3rem;
    :hover {
      color: white;
    }
  }
`;

const Checkout = styled(Button)`
  width: 100%;
  display: flex;
  background: ${theme('colors.green')};
  color: white;
  text-transform: uppercase;
`;

const DiscountTitle = styled('span')`
  color: ${theme('colors.discountedPrice')};
`;
const DiscountPrice = styled(Price)`
  color: ${theme('colors.discountedPrice')};
`;

const CloseModal = styled('div')`
  position: absolute;
  top: 0;
  right: 0;
`;
const EmptyButtonIcon = styled(FontAwesomeIcon)`
  font-size: 2rem;
  margin-right: 10px;
  color: #a5a5a5;
`;

function getPrice(products, before, getDiscount) {
  var priceBefore = {
    incVat: 0,
    exVat: 0,
    vat: 0,
  };
  var priceNow = {
    incVat: 0,
    exVat: 0,
    vat: 0,
  };

  for (let i = 0; i < products.length; i++) {
    const product = products[i];
    var quantity = product.quantity;
    priceBefore.incVat =
      priceBefore.incVat + product.product.previousPrice.incVat * quantity;
    priceBefore.exVat =
      priceBefore.exVat + product.product.previousPrice.exVat * quantity;
    priceBefore.vat =
      priceBefore.vat + product.product.previousPrice.vat * quantity;
    if (product.variant) {
      priceNow.incVat =
        priceNow.incVat + product.variant.price.incVat * quantity;
      priceNow.exVat = priceNow.exVat + product.variant.price.exVat * quantity;
      priceNow.vat = priceNow.vat + product.variant.price.vat * quantity;
    } else {
      priceNow.incVat =
        priceNow.incVat + product.product.price.incVat * quantity;
      priceNow.exVat = priceNow.exVat + product.product.price.exVat * quantity;
      priceNow.vat = priceNow.vat + product.product.price.vat * quantity;
    }
  }

  if (getDiscount) {
    return {
      incVat: priceBefore.incVat - priceNow.incVat,
      exVat: priceBefore.exVat - priceNow.exVat,
      vat: priceBefore.vat - priceNow.vat,
    };
  } else if (before) {
    return priceBefore;
  } else {
    return priceNow;
  }
}

const CartFlyoutView = ({ cart, modal, isOpen, ...rest }) => {
  const items = get(cart, 'data.cart.items', []);
  const rawCheckoutUrl = get(cart, 'data.cart.externalCheckoutUrl');
  let checkoutUrl = rawCheckoutUrl;
  const urlComponents = checkoutUrl?.split('&');
  if (urlComponents) {
    urlComponents.forEach((component, index) => {
      if (component.includes('currency')) {
        urlComponents[index] = 'currency=SEK';
      } else if (component.includes('country')) {
        urlComponents[index] = 'country=SE';
      }
    });
    checkoutUrl = urlComponents.join('&');
  }
  if (items.length === 0) {
    return (
      <Flyout isOpen={isOpen} {...rest}>
        <div css={{ padding: '2em' }}>{t('No items in cart.')}</div>
      </Flyout>
    );
  }
  return (
    <Flyout isOpen={isOpen} {...rest}>
      <div className={'scroll-wrapper'}>
        <Header>
          <CartHeaderIcon icon={['fal', 'shopping-cart']} />
          <h2>
            Din kundvagn
            <VatContext.Consumer>
              {({ incVat }) => (
                <small
                  style={{
                    display: 'block',
                    fontSize: '13px',
                    textTransform: 'none',
                  }}
                >
                  {incVat
                    ? 'Priserna visas inkl. moms'
                    : 'Priserna visas exkl. moms'}
                </small>
              )}
            </VatContext.Consumer>
          </h2>
          <CloseModal onClick={modal.hideTarget}>
            <EmptyButtonIcon icon={['fal', 'times']} />{' '}
          </CloseModal>
        </Header>

        <CartItems data-testid="cart-item-wrapper">
          {items.map((item) => (
            <CartItem item={item} key={item.id} />
          ))}
        </CartItems>

        <Summary>
          <SummaryTitle>Summa</SummaryTitle>
          <SummaryRow>
            <span>Ordinarie pris:</span>
            <div>
              <VatContext.Consumer>
                {({ incVat }) => (
                  <Price includeVat={incVat} price={getPrice(items, true)} />
                )}
              </VatContext.Consumer>
            </div>
          </SummaryRow>

          {getPrice(items, true, true).incVat > 0 && (
            <SummaryRow>
              <DiscountTitle>Produktrabatt:</DiscountTitle>
              <VatContext.Consumer>
                {({ incVat }) => (
                  <DiscountPrice
                    includeVat={incVat}
                    price={getPrice(items, true, true)}
                  />
                )}
              </VatContext.Consumer>
            </SummaryRow>
          )}

          <TotalSummary>
            <label>Totalt:</label>
            <VatContext.Consumer>
              {({ incVat }) => (
                <Price
                  includeVat={incVat}
                  price={cart.data.cart.productPreviousTotal}
                />
              )}
            </VatContext.Consumer>
          </TotalSummary>

          {/* <DeliverySummary>
            {cart.data.cart.productTotal.incVat >= 2000 && (
              <DeliverySummaryText>Vi bjuder på frakten!</DeliverySummaryText>
            )}
          </DeliverySummary> */}
        </Summary>

        {/* {checkoutUrl && (
        <Analytics>
          {track => (
            <Checkout
              anchor={true}
              href={checkoutUrl}
              onClick={() => {
                track(trackCartCheckout({ cart: cart.data.cart }));
              }}
            >
              {t('Check out')}
            </Checkout>
          )})
        </Analytics>
      )} */}

        <CheckoutButtonWrapper>
          <Checkout anchor={true} href={checkoutUrl}>
            {t('Check out')}
          </Checkout>
        </CheckoutButtonWrapper>
      </div>
    </Flyout>
  );
};

const CartFlyout = (props) => (
  // Wrap the flyout with the needed providers
  <CartProvider query={cartQuery}>
    {(cart) => (
      <Fragment>
        <DrawerTarget id="cart-drawer">
          {(drawer) => (
            <Drawer isOpen={drawer.isOpen} right>
              <MenuTitle onClick={drawer.hideTarget}>
                <CloseIcon icon={['fal', 'times']} /> {t('Close')}
              </MenuTitle>
              <CartFlyoutView
                isOpen={drawer.isOpen}
                modal={drawer}
                cart={cart}
                {...props}
              />
            </Drawer>
          )}
        </DrawerTarget>
      </Fragment>
    )}
  </CartProvider>
);

export default CartFlyout;
